var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './SearchResult.style';
import Anchor from '../../atoms/Anchor';
var SearchResult = function SearchResult(_ref) {
  var className = _ref.className,
    title = _ref.title,
    shortDescription = _ref.shortDescription,
    path = _ref.path;
  return __jsx("div", {
    className: "nva-search-result ".concat(className)
  }, __jsx("h3", {
    className: "nva-search-result__title u-h3 u-m-0"
  }, title), __jsx("p", {
    className: "nva-search-result__description u-body1 u-m-0 max-md:u-mb-3"
  }, shortDescription), __jsx(Anchor, {
    href: path,
    className: "nva-search-result__link u-button u-m-0"
  }, window.location.origin + path));
};
export default styled(SearchResult).withConfig({
  componentId: "sc-g2wwqi-0"
})(["", ";"], styles);