import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import cn from 'classnames';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { A11y, Autoplay, EffectFade, Parallax } from 'swiper/modules';
import styles from './Slider.style.js';
import SliderControls from './SliderControls.js';
function Slider(_ref) {
  var children = _ref.children,
    className = _ref.className,
    isHero = _ref.isHero,
    heroCarouselData = _ref.heroCarouselData;
  var carouselOptions = heroCarouselData.carouselOptions,
    mobileHeroVariation = heroCarouselData.mobileHeroVariation;
  var _useState = useState(false),
    isSliding = _useState[0],
    setIsSliding = _useState[1];
  var modifiers = {
    mobileHeroVariation: mobileHeroVariation === 'Full' ? 'nva-slider--mobile-full' : 'nva-slider--mobile-split',
    fullWidth: (carouselOptions === null || carouselOptions === void 0 ? void 0 : carouselOptions.fullWidth) && 'nva-slider--full-width u-full-width'
  };
  var modules = [A11y, Autoplay];
  var options = {
    spaceBetween: 0,
    slidesPerView: 1,
    rewind: true,
    // If loop is used instead, it requires a fix for bg video re-rendering issues
    autoplay: {
      enabled: (carouselOptions === null || carouselOptions === void 0 ? void 0 : carouselOptions.autoplay) === false ? false : true,
      delay: heroCarouselData.timePerSlide || 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  };
  return __jsx("section", {
    className: cn('nva-slider', isHero && 'nva-slider--hero', 'nva-slider--controls-compact', modifiers.mobileHeroVariation, modifiers.fullWidth, className, isSliding && 'is-sliding')
  }, __jsx(Swiper, _extends({
    className: "nva-slider__swiper",
    a11y: {
      enabled: true
    }
  }, options, {
    modules: modules,
    rewind: true,
    watchSlidesProgress: true,
    onSlideChangeTransitionStart: function onSlideChangeTransitionStart() {
      return setIsSliding(true);
    },
    onSlideChangeTransitionEnd: function onSlideChangeTransitionEnd() {
      return setIsSliding(false);
    }
  }), children, heroCarouselData.cardsCollection.items.length > 1 && __jsx(SliderControls, {
    heroCarouselData: heroCarouselData
  })));
}
Slider.Slide = function Slide(_ref2) {
  var children = _ref2.children,
    index = _ref2.index;
  return __jsx(SwiperSlide, {
    className: cn('nva-slider__slide', "nva-slider__slide--".concat(index + 1))
  }, children);
};
// https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
Slider.Slide.displayName = 'SwiperSlide';

// TODO: add prop types
// Slider.defaultProps = {
// };

// Slider.propTypes = {
//   className: PropTypes.string,
// };

export default styled(Slider).withConfig({
  componentId: "sc-1pe9sc3-0"
})(["", ";"], styles);